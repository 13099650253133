import React, { FC, useEffect, useState } from 'react';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { AmountType, SelectedAmountStatuses } from '../../../../types';
import TextInput from '../../Components/TextInput';
import Button from '../../Components/Button';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './CustomTipInput.st.css';
import { CustomTipInputDatahooks } from '../../datahooks';
import { getSelectedPreset } from '../../../../utils';

const CustomTipInput: FC = () => {
  const {
    presetInfo,
    checkoutInfo,
    selectedAmount,
    actions: { selectTipAmount },
  } = useApplicationContext();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<string>(
    selectedAmount?.amount || '0',
  );
  const [buttonState, setButtonState] = useState<SelectedAmountStatuses>(
    selectedAmount?.status || SelectedAmountStatuses.IDLE,
  );
  const selectedPreset = getSelectedPreset(presetInfo?.presets!);

  useEffect(() => {
    if (selectedAmount?.status) {
      setButtonState(selectedAmount?.status);
    }
  }, [selectedAmount?.status]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const textInput = e.target.value;

    if (selectedAmount?.amount === textInput) {
      setButtonState(SelectedAmountStatuses.SUCCESS);
    } else {
      setButtonState(SelectedAmountStatuses.IDLE);
    }

    setInputValue(textInput);
  };

  return (
    <div className={classes.root} data-hook={CustomTipInputDatahooks.MAIN}>
      <div className={classes.textInputWrapper}>
        <TextInput
          data-hook={CustomTipInputDatahooks.TEXT_INPUT}
          currency={checkoutInfo?.checkout?.currency || 'USD'}
          value={inputValue}
          validateRegx={/^(0|[1-9]\d*)(\.\d{0,2})?$/}
          type="number"
          inputMode="decimal"
          onChange={handleInputChange}
          min={0}
          step={0.01}
          onFocus={(e) => e.target.select()}
        />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          data-hook={CustomTipInputDatahooks.BUTTON}
          className={classes.button}
          idleContent={t('app.wix-tips.widget.button.label')}
          state={buttonState}
          onClick={() =>
            selectTipAmount({
              presetId: selectedPreset.id,
              selectedAmount: {
                amount: inputValue,
                type: AmountType.Fixed!,
              },
            })
          }
        />
      </div>
    </div>
  );
};

export default CustomTipInput;
