import {
  TipSettings,
  TipType,
  Preset as SettingsTipPreset,
} from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import { AmountType, Preset, PresetType } from '../../types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { generateUUID } from '..';

export const presetMapper = ({
  settings,
  flowAPI,
  currency,
}: {
  settings: TipSettings;
  flowAPI: ControllerFlowAPI;
  currency: string;
}): Preset[] => {
  if (settings.tipType === TipType.UNKNOWN_TIP_TYPE) {
    return [];
  }

  let presets: Preset[] = [];

  if (settings.presets?.length) {
    const zeroPreset: Preset = createPreset({
      tipPreset: 0,
      tipType: settings.tipType,
      flowAPI,
      currency,
      isSelected: settings.presets.every((preset) => !preset.default),
    });

    presets = [
      zeroPreset,
      ...(settings.presets?.map((tipPreset: SettingsTipPreset) =>
        createPreset({
          tipPreset: tipPreset.value!,
          tipType: settings.tipType,
          flowAPI,
          currency,
          isSelected: tipPreset.default || false,
        }),
      ) || []),
    ];
  }

  if (settings.allowCustomTip) {
    const customPreset = createPreset({
      tipPreset: 0,
      tipType: settings.tipType,
      presetType: PresetType.Custom,
      flowAPI,
      currency,
      isSelected: !settings.presets?.length,
    });

    presets.push(customPreset);
  }

  return presets;
};

const createPreset = ({
  tipPreset,
  tipType,
  presetType = PresetType.Predefined,
  isSelected = false,
  flowAPI,
  currency,
}: {
  tipPreset: number;
  tipType?: TipType;
  isSelected?: boolean;
  presetType?: PresetType;
  flowAPI: ControllerFlowAPI;
  currency: string;
}): Preset => {
  const currencyFormatter = flowAPI.getCurrencyFormatter({
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let formattedValue = '';

  if (tipType === TipType.PERCENTAGE) {
    formattedValue = `${tipPreset}%`;
  } else {
    formattedValue = currencyFormatter({ currency, value: tipPreset });
  }

  const presetId = generateUUID();

  return {
    id: presetId,
    amount: `${tipPreset}`,
    amountType: amountTypeMapper(tipType),
    type: presetType,
    formattedValue,
    isSelected,
  };
};

const amountTypeMapper = (tipType?: TipType): AmountType => {
  if (tipType === TipType.PERCENTAGE) {
    return AmountType.Precentage;
  }

  return AmountType.Fixed;
};

export const getSelectedPreset = (presets: Preset[] = []): Preset =>
  presets.find((preset) => preset.isSelected)!;

export const getPresetById = (presets: Preset[] = [], id: string): Preset =>
  presets.find((preset) => preset.id === id)!;
