import React, { FC, useState } from 'react';
import { TextField, TextFieldProps } from 'wix-ui-tpa/cssVars';
import { TextInputDatahooks } from '../../datahooks';
import { classes, st } from './TextInput.st.css';

export interface TextInputProps extends TextFieldProps {
  currency?: string;
  validateRegx?: RegExp;
}

const getCurrencyIcon = (currency?: string) => {
  if (!currency) {
    return;
  }

  const currencyFormatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
  });

  return currencyFormatter.formatToParts()[0].value;
};

const TextInput: FC<TextInputProps> = ({
  currency,
  className,
  validateRegx,
  value,
  onChange,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let isValid = true;
    if (validateRegx) {
      isValid = e.target.value === '' || !!e.target.value.match(validateRegx);
    }
    if (isValid && onChange) {
      setInputValue(e.target.value);
      onChange(e);
    }
  };

  return (
    <div
      data-hook={rest['data-hook'] || TextInputDatahooks.MAIN}
      className={classes.root}
    >
      <TextField
        {...rest}
        value={inputValue}
        onChange={handleOnChange}
        data-hook={TextInputDatahooks.TEXT_FIELD}
        className={st(className, classes.textField)}
        newErrorMessage
        prefix={getCurrencyIcon(currency)}
      />
    </div>
  );
};

export default TextInput;
