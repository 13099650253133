import React from 'react';
import { BoxSelection, BoxSelectionOptionProps } from 'wix-ui-tpa/cssVars';
import Text from '../../../Components/Text';
import { classes } from '../TipAmountSelector.st.css';
import {
  Preset,
  PresetType,
  SelectedAmount,
  SelectedAmountStatuses,
} from '../../../../../types';
import { TFunction } from '@wix/yoshi-flow-editor';
import { TipAmountSelectorDatahooks } from '../../../datahooks';
import Loader from './Loader';

export type PresetArgs = {
  preset: Preset;
  selectedAmount?: SelectedAmount;
  ref?: (el: HTMLInputElement) => void;
};

const getDeafultBoxSelectionOptionProps = ({
  preset,
  selectedAmount,
  ref,
}: PresetArgs): BoxSelectionOptionProps & { key: string; ref?: any } => ({
  id: preset.id,
  key: preset.id,
  checked: preset.isSelected,
  disabled: selectedAmount?.status === SelectedAmountStatuses.IN_PROGRESS,
  ...(ref ? { ref } : {}),
});

const renderBoxSelectionOption = ({
  preset,
  selectedAmount,
  ref,
}: PresetArgs) => (
  <BoxSelection.Option
    {...getDeafultBoxSelectionOptionProps({ preset, selectedAmount, ref })}
  >
    <Loader preset={preset}>
      <Text
        className={classes.boxSelectionOptionText}
        data-hook={`${TipAmountSelectorDatahooks.BOX_SELECTION_OPTION}-${preset.id}`}
      >
        {preset.formattedValue}
      </Text>
    </Loader>
  </BoxSelection.Option>
);

const rendeCustomBoxSelectionOption = ({
  preset,
  t,
  selectedAmount,
  ref,
}: PresetArgs & { t: TFunction }) => (
  <BoxSelection.Option
    {...getDeafultBoxSelectionOptionProps({ preset, selectedAmount, ref })}
    className={classes.customAmount}
  >
    <Text
      className={classes.boxSelectionOptionText}
      data-hook={`${TipAmountSelectorDatahooks.BOX_SELECTION_OPTION_CUSTOM}-${preset.id}`}
    >
      {t('app.wix-tips.widget.custom-amount-button.text')}
    </Text>
  </BoxSelection.Option>
);

export const renderPreset = ({
  preset,
  t,
  selectedAmount,
  ref,
}: PresetArgs & { t: TFunction }) => {
  if (preset.type === PresetType.Custom) {
    return rendeCustomBoxSelectionOption({ preset, t, selectedAmount, ref });
  }
  return renderBoxSelectionOption({ preset, selectedAmount, ref });
};
