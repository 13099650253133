import { useEffect, useRef, useState } from 'react';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { SelectedAmountStatuses } from '../../../../types';
import { getSelectedPreset } from '../../../../utils';

export const useAutoFocusSelectionOptionAfterProcessing = () => {
  const boxSelectionRefs = useRef<Record<string, HTMLInputElement>>({});
  const { selectedAmount, presetInfo } = useApplicationContext();
  const [firstRender, setFirstRender] = useState(true);

  const selectedPreset = getSelectedPreset(presetInfo?.presets);

  useEffect(() => {
    if (
      selectedAmount?.status === SelectedAmountStatuses.SUCCESS ||
      selectedAmount?.status === SelectedAmountStatuses.FAILURE
    ) {
      if (firstRender) {
        setFirstRender(false);
      } else {
        boxSelectionRefs.current?.[selectedPreset?.id]?.focus();
      }
    }
  }, [selectedAmount?.status]);

  const addToRef = (el: HTMLInputElement, presetId: string) => {
    if (!boxSelectionRefs.current[presetId]) {
      boxSelectionRefs.current[presetId] = el;
    }
  };

  return { addToRef };
};
