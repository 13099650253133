import React, { FC, useEffect } from 'react';
import { BoxSelection, BoxSelectionBoxSize } from 'wix-ui-tpa/cssVars';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { classes, st } from './TipAmountSelector.st.css';
import { PresetType, SelectedAmountStatuses } from '../../../../types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { renderPreset } from './Presets';
import { TipAmountSelectorDatahooks } from '../../datahooks';
import { getPresetById, getSelectedPreset } from '../../../../utils';
import CustomTipInput from '../CustomTipInput';
import ErrorMessage from '../ErrorMessage';
import { useAutoFocusSelectionOptionAfterProcessing } from './useAutoFocusSelectionOptionAfterProcessing';

const TipAmountSelector: FC = () => {
  const { presetInfo, selectedAmount, actions } = useApplicationContext();
  const { t } = useTranslation();
  const { addToRef } = useAutoFocusSelectionOptionAfterProcessing();

  const selectedPreset = getSelectedPreset(presetInfo?.presets);

  useEffect(() => {
    actions.selectTipAmount({
      presetId: selectedPreset?.id!,
      selectedAmount: {
        amount: selectedPreset?.amount!,
        type: selectedPreset?.amountType!,
      },
    });
  }, []);

  const handlePresetChange = ({ id }: { id: string }) => {
    const presetToChange = getPresetById(presetInfo?.presets, id);
    actions.selectTipAmount({
      presetId: id,
      selectedAmount: {
        amount: presetToChange?.amount,
        type: presetToChange?.amountType,
      },
    });
  };

  return (
    <div
      className={st(classes.root, {
        disabled: selectedAmount?.status === SelectedAmountStatuses.IN_PROGRESS,
      })}
      data-hook={TipAmountSelectorDatahooks.MAIN}
    >
      <BoxSelection
        name="tip-amount-selector"
        data-hook={TipAmountSelectorDatahooks.BOX_SELECTION}
        className={classes.boxSelection}
        onChange={handlePresetChange}
        size={BoxSelectionBoxSize.small}
      >
        {presetInfo?.presets.map((preset) =>
          renderPreset({
            preset,
            t,
            selectedAmount,
            ref: (el: HTMLInputElement) => addToRef(el, preset.id),
          }),
        )}
      </BoxSelection>
      {selectedPreset?.type === PresetType.Custom && <CustomTipInput />}
      <ErrorMessage />
    </div>
  );
};

export default TipAmountSelector;
